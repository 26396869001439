<template>
  <th-page-wrapper>
    <dialog-form />
    <th-datatable
      ref="tagsTable"
      :key="$route.fullPath"
      do-route
      do-route-filters
      force-meta-check
      resource="tagsV1"
      meta-resource="tags"
      route-base="/products/tags"
      show-search-filter
      sortable
      :buttons="computedButtons"
      :headers="headers"
      :meta-options="resourceQuery"
      :operations="getOperations()"
      :resource-query="{ query: resourceQuery }"
      :show-operations="true"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import qs from 'qs'
import DialogForm from './components/dialog-form'

export default {
  name: 'TagsAll',
  metaInfo() {
    return {
      title: this.$t('pages.tags.title')
    }
  },
  components: {
    DialogForm
  },
  data() {
    return {
      selectedItems: [],
      resourceQuery: {
        deleted: false
      },
      headers: [
        {
          field: 'name',
          label: this.$t('pages.tags.all.headers.tag'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'product_count',
          label: this.$t('pages.tags.all.headers.number_of_products'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:tags:create']
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    refresh() {
      this.$refs.tagsTable.refresh()
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.tags.title')
        })
      })
    },
    getOperations() {
      return {
        link: {
          text: this.$t('pages.tags.all.view_products'),
          disable: (row) => {
            return row && !row.product_count
          },
          handleClick: (row) => {
            const query = qs.stringify(
              { filter: { tags: [row.id] } },
              { addQueryPrefix: true },
              ''
            )
            this.$router.push(`/products/manager${query}`)
          }
        }
      }
    }
  }
}
</script>
