<template>
  <th-modal
    :title="$t('common.resource.tag.singular')"
    width="30%"
    name="tag"
    @close="handleClose"
  >
    <!-- W3C regulates that: when there is only one single-line text input field in a form,
      the user agent should accept Enter in that field as a request to submit the form.
      To prevent this behavior, we add @submit -->
    <el-form
      ref="tagsForm"
      :rules="rules"
      :model="form"
      @submit.prevent="submitForm"
    >
      <!-- Name -->
      <el-form-item prop="name" :label="$t('common.titles.name')" class="mb-0">
        <el-input v-model="form.name" />
      </el-form-item>
    </el-form>

    <template #footer>
      <!-- Delete -->
      <el-button
        v-if="!isNew"
        v-permissions="{ scopes: ['products:tags:delete'] }"
        icon="Delete"
        class="el-button--text-icon"
        :disabled="!deletable"
        @click="handleDelete"
      />

      <div class="flex-1" />

      <!-- Cancel -->
      <el-button @click="handleClose">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <!-- Save -->
      <el-button
        v-if="!isNew"
        v-permissions="{ scopes: ['products:tags:update'] }"
        type="primary"
        @click="submitForm"
      >
        {{ $t('common.interactions.buttons.save') }}
      </el-button>

      <!-- Create -->
      <el-button
        v-else
        v-permissions="{ scopes: ['products:tags:create'] }"
        type="primary"
        @click="submitForm"
      >
        {{ $t('common.interactions.buttons.create') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  name: 'TagsEditFormDialog',
  data() {
    return {
      isNew: true,
      id: null,
      deletable: false,
      form: {
        name: null
      },
      rules: {
        name: {
          required: true,
          message: this.$t('pages.tags.form.message.exception.empty'),
          trigger: 'change'
        }
      }
    }
  },
  watch: {
    $route: function (newRoute) {
      this.setFormNewEdit(newRoute)
    }
  },
  mounted() {
    this.setFormNewEdit(this.$route)
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'products-tags-all' })
    },
    setFormNewEdit(route) {
      if (route.name === 'products-tags-edit') {
        const id = route.params && route.params.id
        this.setStateEdit(id)
      } else if (route.name === 'products-tags-new') {
        this.setStateNew()
      } else {
        this.$thModal.hide('tag')
      }
    },
    setStateNew() {
      this.id = null
      this.isNew = true
      this.$thModal.show('tag')
      this.form.name = null
    },
    async setStateEdit(id) {
      this.id = id
      this.isNew = false
      this.$thModal.show('tag')
      try {
        const { data } = await th.tags().get(id)
        this.deletable = data.product_count === 0
        this.form.name = data.name
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.tag.singular')
          })
        })
      }
    },
    async submitForm() {
      this.$refs.tagsForm.validate(async (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.message.invalid_inputs')
          })
        }

        if (this.isNew) {
          await th.tags().create(this.form)
          this.$ampli.eventWithBaseProps('tagCreated')
          this.handleClose()
        } else {
          await th.tags().put(this.id, this.form)
          this.handleClose()
        }
      })
    },
    async handleDelete() {
      if (this.deletable) {
        const confirm = await this.$askToDelete(this.form.name || this.form.id)
        if (confirm) {
          try {
            await th.tags().put(this.id, { deleted: true })
            this.handleClose()
          } catch (err) {
            this.$logException(err, {
              message: this.$t('common.error.action.delete.single', {
                resource: this.$t('common.resource.tag.singular')
              })
            })
          }
        }
      }
    }
  }
}
</script>
